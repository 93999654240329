/* index.css */

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 52.5vh;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

/* Additional styling for mobile responsiveness */
@media (max-width: 768px) {
  .page-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .page-container {
    padding: 0;
  }
}
