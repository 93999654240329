.overlay-card-section {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.dark-overlay {
  width: 100%;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.85);
  margin-top: -10px;
}

.services-content-container {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1200px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.services-content-container > .heading {
  font-size: 2.7rem;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.services-content {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
}

.left-section {
  flex: 1;
}

.right-section {
  flex: 1;
}

.services-button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.service-button {
  background: transparent;
  border: 2px solid gray;
  color: gray;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.service-button:hover {
  border-color: white;
  color: white;
}

.service-button.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: white;
  color: white;
}

.text-card {
  background: white;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.8rem;
  margin: 0.5rem 0 !important;
}

.card-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.learn-more-button {
  /* background-color: #ec3f3a; */
  background-color: #344688;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
}

.learn-more-button:hover{
background-color: #0E0F3D;
}

@media screen and (max-width: 1200px) {
  .dark-overlay {
    height: 60vh;
  }
}
/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .dark-overlay {
    height: 80vh;
  }

  .services-content-container {
    bottom: 5%;
    width: 95%;
  }

  .services-content {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }

  .services-content-container > .heading {
    font-size: 2rem;
    text-align: center;
  }

  .services-button-group {
    align-items: center;
  }

  .service-button {
    width: 100%;
    text-align: center;
    padding: 1rem 4rem;
  }

  .right-section {
    width: 100%;
  }

  .text-card {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .learn-more-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .dark-overlay {
    height: 100vh;
  }
  .services-content-container {
    top: 15%;
    bottom: 0;
  }
}
