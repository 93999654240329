/* ImageSlider.css */

.slider-container {
  position: relative;
  width: 100%;
  height: 54.3rem;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
  z-index: 1;
}

/* Slide Text */
.slide-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 6vw; /* Responsive font size */
  font-weight: bold;
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 5px;
}

/* Slide Button */
.slide-button {
  position: absolute;
  top: 70%;
  left: 45%;
  transform: translate(-50%, -50%);
  /* background-color: #ec3f3a; */
  background-color: #344688;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bolder;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.slide-button:hover {
  background-color: #0E0F3D;
}

/* Responsiveness */
@media (max-width: 1024px) {
  .slider-container {
    height: 40rem;
  }

  .slide-text {
    font-size: 4.5vw;
  }

  .slide-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .slider-container {
    height: 35rem;
  }

  .slide-text {
    font-size: 5vw;
    padding: 0.8rem 1rem;
  }

  .slide-button {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .slider-container {
    height: 25rem;
  }

  .slide-text {
    font-size: 12vw;
    padding: 0.5rem 0.8rem;
    left: 50%;
    width: 80%;
  }

  .slide-button {
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
    top: 75%;
    left: 30%;
    right: 30%;
  }
}
