.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  /* background-color: #fff; */
  color: white;
  padding-left: 1rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60px;
  /* background-color: white; */
}

.nav-buttons {
  display: flex;
  gap: 1rem;
}

.nav-button {
  background: transparent;
  color: white;
  border: 1px solid black;
  border-left: 1px solid gray;

  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  transition: border 0.3s;
  padding: 2rem 6rem;
  color: gray;
}

.nav-button:hover {
  border-color: white;
  color: white;
}

.donate-btn {
  /* background-color: #ec3f3a; */
  background-color: #344688;
  color: white;
  border: none;
}

.donate-btn:hover {
  background-color: #0e0f3d;
}

.hamburger-menu {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.drawer-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

@media (max-width: 1200px) {
  .nav-button {
    background: transparent;
    color: white;
    border: 1px solid black;
    border-left: 1px solid gray;

    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    transition: border 0.3s;
    padding: 2rem 4rem;
    color: gray;
  }
}

@media (max-width: 1000px) {
  .nav-button {
    background: transparent;
    color: white;
    border: 1px solid black;
    border-left: 1px solid gray;

    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    transition: border 0.3s;
    padding: 1.5rem 3rem;
    color: gray;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 1rem 2rem;
  }
  .nav-buttons {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .logo {
  max-width: 40px;
  /* background-color: white; */
}
}
