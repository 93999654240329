/* AboutUsSection.css */

.about-us-section {
  background-color: transparent;
  padding: 3rem 1.5rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.small-heading {
  font-size: 1rem;
  font-weight: 500;
  color: #b0b0b0;
  letter-spacing: 1.2px;
  margin-bottom: 0.5rem;
}

.main-heading {
  font-size: 3rem;
  font-weight: 700;
  color: black;
  margin-bottom: 2rem;
}

.roles-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.role-box {
  flex: 1 1 30%; /* Three boxes per row */
  min-width: 150px;
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
}

.role-box:hover,
.role-box.active {
  background-color: black;
  color: white;
}

.role-description {
  padding: 1rem 2rem;
  margin-top: 1rem;
  background-color: white;
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.description-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.description-text {
  color: #666;
  font-size: 1rem;
}

/* Responsiveness */
@media (max-width: 1024px) {
  .main-heading {
    font-size: 2.5rem;
  }

  .role-box {
    flex: 1 1 45%; /* Two boxes per row */
  }

  .description-heading {
    font-size: 1.25rem;
  }

  .description-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .about-us-section {
    padding: 2rem 1rem;
  }

  .main-heading {
    font-size: 2rem;
  }

  .role-box {
    flex: 1 1 100%; /* One box per row */
    margin-bottom: 1rem;
  }

  .description-heading {
    font-size: 1.2rem;
  }

  .description-text {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .about-us-section {
    padding: 1.5rem 0.5rem;
  }

  .main-heading {
    font-size: 1.8rem;
  }

  .small-heading {
    font-size: 0.9rem;
  }

  .role-box {
    flex: 1 1 100%; /* One box per row */
    padding: 0.8rem;
    font-size: 0.9rem;
  }

  .description-heading {
    font-size: 1rem;
  }

  .description-text {
    font-size: 0.8rem;
  }
}
