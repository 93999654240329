.black-main-heading {
  font-family: "Rajdhani", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.06em;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

@media screen and (max-width: 480px) {
  .black-main-heading {
    font-size: 2rem;
  }
}
