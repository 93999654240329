.small-gray-heading {
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #939293;
  margin: 1rem;
}

@media screen and (max-width: 480px) {
  .small-gray-heading {
    font-size: 10px;
  }
}
