.about-us {
  /* font-family: Arial, sans-serif; */
  color: #333;
  line-height: 1.6;
}

/* Image Section */
.image-section {
  position: relative;
  width: 100%;
}

.large-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Content Section */
.content-section {
  padding: 2rem 10%;
  text-align: center;
}

.main-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  color: #333;
}

/* Text Content */
.text-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.text-content .left-section {
  text-align: left;
}

.text-content h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  /* color: #ec3f3a; */
  color: #344688;
}

.text-content p {
  margin-bottom: 1.5rem;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .content-section {
    padding: 2rem 5%;
  }

  .main-heading {
    font-size: 2rem;
  }

  .text-content h2 {
    font-size: 1.5rem;
  }

  .text-content p {
    font-size: 0.9rem;
  }
}
