/* Footer.css */

.footer {
  background-color: #222;
  /* background-color: black; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 2rem; */
  padding: 2rem;
  flex-wrap: wrap;
  text-align: left;
  flex-shrink: 0;
}

.footer-column {
  flex: 1;
  text-align: center;
  margin: 0 1rem;
  max-width: 300px;
}

.left-ft {
  text-align: left;
}

.right-ft {

  text-align: right;
}

.footer-logo {
  max-width: 50px;
  /* margin-bottom: 1rem; */
}

.footer-heading {
  font-family: "Rajdhani", sans-serif;
  font-size: 2rem;
  margin: 1rem 0 0.5rem;
  color: #f3f3f3;
}

.footer-subheading {
  font-family: "Rajdhani", sans-serif;
  font-size: 1.2rem;
  margin: 1rem 0 0.5rem;
  color: #f3f3f3;
}

.footer-text {
  font-size: 0.9rem;
  color: #ddd;
  line-height: 1.5;
}

.linkedin-icon {
  margin-top: 0.5rem;
  color: #ddd;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.linkedin-icon:hover {
  color: #344688;
  background-color: #ddd;
  border-radius: 2px;
}

.linkedin-logo {
  margin-top: 0.5rem;
  margin-right: -0.5rem;;
  /* color: #ddd; */
  /* font-size: 24px; */
  width: 74px;
  /* height: 24px; */
}

.footer-partner-logo {
  width: 200px;
  /* height: 50px; */
  margin-bottom: 1rem;
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.footer-button {
  background: transparent;
  color: white;
  border: 2px solid white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-align: center;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.footer-button:hover {
  background: white;
  color: #222;
}

.footer-donate-button {
  background: #344688;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.3s ease;
}

.footer-donate-button:hover {
  background: #0e0f3d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
  }

  .footer-column {
    max-width: 100%;
    margin: 1rem 0;
  }

  .footer-logo {
    width: 120px;
  }

  .footer-partner-logo {
    width: 150px;
  }

  .footer-buttons {
    gap: 1rem;
  }

  .footer-button,
  .footer-donate-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
  }
  .left-ft {
    text-align: center;
  }
  
  .right-ft {
  
    text-align: center;
  }
}
