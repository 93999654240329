.auth-container {
  width: 90%;
  max-width: 400px;
  margin: 5rem auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.auth-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.auth-subtitle {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #666;
}

.auth-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
}

.auth-input {
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-input:focus {
  outline: none;
  /* border-color: #ec3f3a; */
  border-color: #344688;
}

.auth-button {
  padding: 0.8rem;
  font-size: 1rem;
  /* background-color: #ec3f3a; */
  background-color: #344688;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.auth-button:hover {
  /* background-color: #d2332f; */
  background-color: #0e0f3d;
}

.auth-footer {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.error {
  color: #ec3f3a;
}

.auth-footer a {
  /* color: #ec3f3a; */
  color: #344688;
  text-decoration: none;
}

.auth-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .auth-container {
    padding: 2%;
  }
}
