.page-template {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 1rem;
}

.back-button {
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  cursor: pointer;
}

.page-heading {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  word-wrap: break-word;
}

.page-paragraph {
  font-size: 1rem;
  margin: 1rem 0;
  color: #555;
}

.page-tabs .ant-tabs-nav {
  justify-content: center;
  overflow-x: auto; /* Ensure tabs are scrollable on smaller screens */
  white-space: nowrap;
}

.tab-content {
  text-align: left;
  font-size: 1rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 5px;
  min-height: 50vh;
}

@media screen and (max-width: 768px) {
  .page-template {
    padding: 0.5rem;
  }

  .page-header {
    margin-bottom: 0.5rem;
  }

  .back-button {
    font-size: 1.2rem;
  }

  .page-heading {
    font-size: 1.5rem;
    margin-left: 2rem; /* Provide space for the back button */
    margin-right: 2rem;
  }

  .page-paragraph {
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }

  .page-tabs .ant-tabs-nav {
    overflow-x: auto;
    justify-content: flex-start; /* Align tabs to the start for better UX */
    padding-bottom: 0.5rem;
  }

  .tab-content {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .page-template {
    padding: 0.5rem;
    max-width: 380px;
    margin: auto;
  }

  .page-heading {
    font-size: 1.2rem;
  }

  .page-paragraph {
    font-size: 0.8rem;
  }

  .tab-content {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .back-button {
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .page-template {
    padding: 0.5rem;
    max-width: 350px;
    margin: auto;
  }
}
