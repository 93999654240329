/* ImageGrid.css */

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 0 0;
  width: 90%;
  margin: 0 auto;
}

.small-heading {
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #939293;
  margin: 1rem;
}

.main-section-heading,
.main-section-heading-part {
  font-family: "Rajdhani", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.06em;
  text-transform: uppercase;
}

.main-section-heading-part {
  margin-bottom: 2.8rem;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  /* gap: 1rem;  */
  justify-content: center;
}

.image-item {
  position: relative;
  flex: 1;
  min-width: calc(25% - 1rem);
  max-width: calc(25% - 1rem);
  overflow: hidden;
}

.grid-image {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-item:hover .grid-image {
  transform: scale(1.1);
}

/* Permanent heading text centered on the image */
.image-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  z-index: 1;
  text-align: center;
  transition: top 0.4s ease;
  white-space: nowrap;
}

.image-item:hover .image-text {
  top: 5%;
}

/* Bottom overlay for description and button on hover */
.bottom-overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  margin: auto;
  background-color: #171617;
  color: #fff;
  padding: 1rem 0;
  /* text-align: justify; */
  transition: bottom 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-item:hover .bottom-overlay {
  bottom: 0;
}

.overlay-description {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.join-button {
  /* background-color: #ec3f3a; */
  background-color: #344688;
  color: white;
  border: none;
  padding: 16px 32px;
  cursor: pointer;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.join-button:hover{
background-color: #0E0F3D;
}

@media (max-width: 1280px) {
  .image-item {
    position: relative;
    flex: 1;
    min-width: calc(33.33% - 1rem);
    max-width: calc(33.33% - 1rem);
    overflow: hidden;
  }
}

/* Responsiveness */
@media (max-width: 1024px) {
  .image-item {
    min-width: calc(50% - 1rem); /* Two items per row */
    max-width: calc(50% - 1rem);
  }

  .main-section-heading,
  .main-section-heading-part {
    font-size: 3rem;
  }

  .small-heading {
    font-size: 14px;
  }

  .image-text {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .image-item {
    min-width: calc(50% - 1rem); /* Two items per row for smaller tablets */
    max-width: calc(50% - 1rem);
  }

  .main-section-heading,
  .main-section-heading-part {
    font-size: 2.5rem;
  }

  .small-heading {
    font-size: 12px;
  }

  .image-text {
    font-size: 1.8rem;
  }

  .join-button {
    padding: 12px 24px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .image-grid {
    gap: 0.5rem; /* Reduce spacing for smaller screens */
  }

  .image-item {
    min-width: 100%; /* Single item per row */
    max-width: 100%;
  }

  .main-section-heading,
  .main-section-heading-part {
    font-size: 2rem;
    text-align: center;
  }

  .small-heading {
    font-size: 10px;
    text-align: center;
  }

  .image-text {
    font-size: 1.5rem;
    padding: 0 0.5rem; /* Adjust padding for smaller screens */
  }

  .join-button {
    padding: 10px 20px;
    font-size: 0.8rem;
  }

  .overlay-description {
    font-size: 12px;
    margin: 0.2rem 0;
  }
}

/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------

/* ImageGrid.css */

/* .section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 0 0;
  width: 90%;
  margin: 0 auto;
}

.small-heading {
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #939293;
  margin: 1rem;
}

.main-section-heading,
.main-section-heading-part {
  font-family: "Rajdhani", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.06em;
  text-transform: uppercase;
}

.main-section-heading-part {
  margin-bottom: 2.8rem;
}

.image-grid {
  display: flex;
  gap: 0;
}

.image-item {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.grid-image {
  width: 100%;
  height: auto;
  aspect-ratio: 3/4;
  transition: transform 0.3s ease;
}

.image-item:hover .grid-image {
  transform: scale(1.1);
}

.image-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  z-index: 1;
  text-align: center;
  transition: top 0.4s ease;
  white-space: nowrap;
}

.image-item:hover .image-text {
  top: 40%;
}


.bottom-overlay {
  position: absolute;
  bottom: -50%; 
  left: 0;
  width: 100%;
  margin: auto;
  background-color: #171617;
  color: #fff;
  padding: 1rem 0;
  text-align: justify;
  transition: bottom 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.image-item:hover .bottom-overlay {
  bottom: 0; 
}

.overlay-description {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.join-button {
  background-color: #ec3f3a;
  color: white;
  border: none;
  padding: 16px 32px;
  cursor: pointer;
  margin-top: 0.5rem;
  font-size: 1rem;
} */
