.why-we-do-section {
  padding: 2rem 1rem; /* Reduced side padding for smaller screens */
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.description-text {
  font-size: 1rem; /* Slightly smaller for better readability on mobile */
  color: #666;
  line-height: 1.6;
  margin-top: 1rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .why-we-do-section {
    padding: 1.5rem 1rem;
  }

  .description-text {
    font-size: 0.9rem; /* Smaller font size for smaller devices */
    line-height: 1.5;
  }
}
