/* General Styling */
.meet-our-team {
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  text-align: center;
}

.team-section {
  margin-bottom: 2.5rem;
  text-align: center;
}

.team-heading {
  font-family: "Rajdhani", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  color: #333;
}

/* Grid Layout */
.team-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;

  gap: 1.5rem;
  justify-content: center;
  align-items: stretch;
  width: 80%;
  margin: auto;
}

.advisory-grid {
  grid-template-columns: repeat(4, 1fr) !important;
  margin: auto;
  width: 70%;
  justify-content: center;
}

/* If the last row has 3 items, make it 3 columns */
.advisory-grid:has(.advisory-card:nth-child(5):nth-last-child(3)) {
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}

.advisory-card {
  width: 100% !important;
  margin: auto;
}

.second-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  justify-content: center;
  width: 70% !important;
  margin-top: 1rem;
}


.advisory-card:nth-child(4) .advisory-img {
  object-fit:cover !important;
}
.advisory-card-1{
width: 100% !important;
margin: auto;
}

/* Card Styling */
.team-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 250px;
}

.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.founders-img {
  object-fit: cover;
  width: 100%;
}

.advisory-img {
  object-fit: contain !important;
  max-width: 200px;
  border-radius: 10px;
}

.advisory-img-1{
width: 240px !important;
}

/* Text and Info */
.team-info {
  padding: 1rem;
  text-align: center;
}

.team-name {
  font-family: "Rajdhani", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: #333;
}

.team-designation {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin: 0.5rem 0;
}

.team-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.4rem;
}

.team-tag {
  background: #344688;
  color: white;
  font-size: 0.75rem;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  text-transform: capitalize;
}

@media (max-width: 1280px) {
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  }
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .team-heading {
    font-size: 1.8rem;
  }

  .team-card {
    box-shadow: none;
  }

  .team-image {
    height: 200px;
  }

  .team-name {
    font-size: 1.2rem;
  }

  .team-designation {
    font-size: 0.9rem;
  }

  .team-tags {
    gap: 0.3rem;
  }

  .team-tag {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }
}

@media (max-width: 480px) {
  .team-heading {
    font-size: 1.6rem;
  }

  .second-row {
    width: 100% !important;
  }

  .team-card {
    margin: 0 auto; /* Center-align cards */
    width: 350px !important;
    min-width: 320px;
  }

  .team-grid {
    width: 100%;
  }

  .team-image {
    height: 180px;
  }

  .team-info {
    padding: 0.8rem;
  }

  .team-name {
    font-size: 1rem;
  }

  .team-designation {
    font-size: 0.85rem;
  }

  .team-tags {
    gap: 0.2rem;
  }

  .team-tag {
    font-size: 0.65rem;
    padding: 0.2rem 0.3rem;
  }

  .founders-img {
    object-fit: cover;
    width: 320px !important;
    height: 320px !important;
    width: 100%;
  }

  .advisory-img {
    object-fit: contain !important;
    width: 320px !important;
    border-radius: 10px;
  }
}

@media (max-width: 360px) {

  .meet-our-team {
    padding: 0;
  }
}
/* MeetOurTeam.css */

/* Modal Styling */
.modal-content {
  text-align: center;
}

.modal-image {
  width: 200px;
  border-radius: 8px;
  height: auto;
  /* max-height: 200px; */
  object-fit: cover;
  margin-bottom: 1rem;
}

/* Other styles (team section, grid, card, etc.) */
.meet-our-team {
  /* padding: 2rem 1rem; */
  background-color: #f9f9f9;
}

.team-section {
  margin-bottom: 2.5rem;
  text-align: center;
}

.team-heading {
  font-family: "Rajdhani", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  color: #333;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: stretch;
}

.team-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.team-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.team-info {
  padding: 1rem;
  text-align: center;
}

.team-name {
  font-family: "Rajdhani", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: #333;
}

.team-designation {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin: 0.5rem 0;
}

.team-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.4rem;
}

.team-tag {
  background: #344688;
  color: white;
  font-size: 0.75rem;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  text-transform: capitalize;
}


/* Founder */
.team-grid-founder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 50vh; /* Adjust based on your layout */
}